<template>
  <div id="customer-list">
    <!-- user total card -->
    <v-row class="mb-5">
      <!--       <v-col
        v-for="total in customerTotalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <v-avatar
              :color="resolveCustomerTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveCustomerTotalIcon(total.title).color}--text`"
            >
              <v-icon
                size="25"
                :color="resolveCustomerTotalIcon(total.title).color"
                class="rounded-0"
              >
                {{ resolveCustomerTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>
      <v-row class="px-2 py-2 ma-0">
        <!-- visibility filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="dimensionVisibleFilter"
            :items="selectOptions.visibility"
            :label="t('Filter Customer: Dimension')"
            item-text="title"
            item-value="value"
            clearable
            dense
            hide-details
            open-on-clear
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="blockedFilter"
            :items="selectOptions.blocked"
            :label="t('Filter Customer: Blocked')"
            item-text="title"
            item-value="value"
            clearable
            dense
            attach
            chips
            multiple
            hide-details
            open-on-clear
            small-chips
          ></v-select>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <!-- <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-row
          align="center"
          align-content="center"
          justify="space-between"
        >
          <v-col
            cols="12"
            md="8"
          >
            <table-search
              v-model="options.q"
              dense
              outlined
              hide-details
              full-width
            />
          </v-col>
        </v-row>
      </v-card-text> -->

      <!-- table -->
      <vuex-table
        :headers="tableColumns"
        :items="customers"
        :options="options"
        :search.sync="search"
        :meta.sync="meta"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [5, 10, 25, 50, 100]
        }"
        @update:options="o => options = o"
      >
        <!-- name -->
        <template #[`item.displayName`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'customer-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.displayName }}
              </router-link>
              <small>{{ item.email }}</small>
            </div>
          </div>
        </template>

        <template #[`item.blocked`]="{item}">
          <v-chip
            v-if="item.blocked"
            small
            class="v-chip-light-bg font-weight-semibold text-uppercase me-1"
            :class="resolveCustomerBlockedVariant(item.blocked)"
          >
            {{ item.blocked }}
          </v-chip>
        </template>
        <template #[`item.dimensionHidden`]="{item}">
          <v-chip
            v-if="item.dimensionHidden"
            small
            class="v-chip-light-bg font-weight-semibold text-uppercase error--text"
          >
            {{ t('HIDE APP') }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                :to="{ name: 'customer-view', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Customer</span>
          </v-tooltip>
        </template>
      </vuex-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPlus, mdiExportVariant, mdiEyeOutline } from '@mdi/js'
import { useUtils } from '@/@core/libs/i18n'
import { onMounted, onUnmounted } from '@vue/composition-api'
import useCustomersList from './useCustomersList'

export default {
  setup() {
    const { t } = useUtils()

    const {
      customerListTable,
      tableColumns,
      roleFilter,
      planFilter,
      statusFilter,
      totalCustomerListTable,
      loading,
      customerTotalLocal,

      resolveCustomerBlockedVariant,
      resolveCustomerStatusVariant,
      resolveCustomerTotalIcon,

      customers,
      meta,
      search,

      filters,
      options,

      blockedFilter,
      dimensionVisibleFilter,

      init,
      destroy,
    } = useCustomersList()

    onMounted(() => {
      init()
    })
    onUnmounted(() => {
      destroy()
    })

    const selectOptions = {
      blocked: [
        { title: t('Blocked: All'), value: 'All' },
        { title: t('Blocked: Ship'), value: 'Ship' },
        { title: t('Blocked: Invoice'), value: 'Invoice' },
      ],
      visibility: [{ title: t('Show App Customers'), value: true }],
    }

    return {
      t,

      customerListTable,
      tableColumns,
      roleFilter,
      planFilter,
      statusFilter,
      totalCustomerListTable,

      loading,
      customerTotalLocal,

      resolveCustomerBlockedVariant,
      resolveCustomerStatusVariant,
      resolveCustomerTotalIcon,

      customers,
      meta,
      search,

      filters,
      options,

      // icons
      icons: {
        mdiPlus,
        mdiEyeOutline,
        mdiExportVariant,
      },

      selectOptions,
      blockedFilter,
      dimensionVisibleFilter,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
