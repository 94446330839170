import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiDnsOutline,
} from '@mdi/js'
// eslint-disable-next-line no-unused-vars, object-curly-newline
import { computed, getCurrentInstance, readonly, ref, toRaw, watch } from '@vue/composition-api'

export default function useCustomersList() {
  const vm = getCurrentInstance().proxy

  const customerListTable = ref([])

  const tableColumns = [
    { text: '#', value: 'number', width: '5%' },
    { text: 'CUSTOMER', value: 'displayName', width: '25%' },
    { text: 'PHONE', value: 'phoneNumber', width: '10%' },
    {
      text: 'LOCATION',
      value: 'location',
      sortable: false,
      width: '20%',
    },
    { text: 'CONTACT', value: 'contact', width: '15%' },
    { text: 'REP', value: 'salesPersonCode', width: '5%' },
    {
      text: 'BLOCKED',
      value: 'blocked',
      sortable: false,
      width: '5%',
    },
    {
      text: 'DIMENSION',
      value: 'dimensionHidden',
      sortable: false,
      width: '5%',
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
      width: '10%',
    },
  ]

  const customers = computed({
    get: () => vm.$store.getters['customers/items'],
  })
  const loading = computed({
    get: () => vm.$store.getters['customers/loading'],
  })
  const meta = computed({
    get: () => vm.$store.getters['customers/meta'],
  })
  const filters = computed({
    get: () => vm.$store.getters['customers/filters'],
  })
  const options = computed({
    get: () => vm.$store.getters['customers/options'],
    set: val => vm.$store.dispatch('customers/setOptions', { ...options.value, ...val }),
  })
  const search = computed({
    get: () => vm.$store.getters['customers/search'],
    set: val => vm.$store.dispatch('customers/setSearch', val),
  })
  const dimensionVisibleFilter = computed({
    get: () => vm.$store.getters['customers/filters']?.dimensionVisible,
    set: val => vm.$store.dispatch('customers/setFilters', { ...filters.value, dimensionVisible: val }),
  })
  const blockedFilter = computed({
    get: () => vm.$store.getters['customers/filters']?.blocked,
    set: val => vm.$store.dispatch('customers/setFilters', { ...filters.value, blocked: val }),
  })
  const totalCustomerListTable = computed({
    get: () => vm.$store.getters['customers/meta'].total,
  })

  const fetchItems = () => vm.$store.dispatch('customers/fetchItems')
  const setOptions = val => vm.$store.dispatch('customers/setOptions', val)
  const setFilters = val => vm.$store.dispatch('customers/setFilters', val)

  const resolveCustomerRoleVariant = role => {
    if (role === 'admin') return 'error'
    if (role === 'customer') return 'primary'

    return 'primary'
  }

  const resolveCustomerRoleIcon = role => {
    if (role === 'admin') return mdiDnsOutline
    if (role === 'customer') return mdiAccountOutline

    return mdiAccountOutline
  }

  const resolveCustomerStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'

    return 'primary'
  }

  const resolveCustomerTotalIcon = total => {
    if (total === 'Total Customers') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Customers') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Customers') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Customers') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  const resolveCustomerBlockedVariant = blocked => {
    if (blocked === 'All') return 'error--text'
    if (blocked === 'Ship') return 'warning--text'
    if (blocked === 'Invoice') return 'accent--text'

    return ''
  }

  const init = () => {
    fetchItems()
  }

  const destroy = () => {}

  return {
    customerListTable,
    tableColumns,

    totalCustomerListTable,
    loading,
    fetchItems,
    resolveCustomerRoleVariant,
    resolveCustomerRoleIcon,
    resolveCustomerStatusVariant,
    resolveCustomerTotalIcon,
    resolveCustomerBlockedVariant,

    meta,
    search,
    customers,

    options,
    filters,

    setOptions,
    setFilters,

    blockedFilter,
    dimensionVisibleFilter,

    init,
    destroy,
  }
}
