import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"customer-list"}},[_c(VRow,{staticClass:"mb-5"}),_c(VCard,[_c(VCardTitle,[_vm._v(" Search & Filter ")]),_c(VRow,{staticClass:"px-2 py-2 ma-0"},[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VSelect,{attrs:{"items":_vm.selectOptions.visibility,"label":_vm.t('Filter Customer: Dimension'),"item-text":"title","item-value":"value","clearable":"","dense":"","hide-details":"","open-on-clear":""},model:{value:(_vm.dimensionVisibleFilter),callback:function ($$v) {_vm.dimensionVisibleFilter=$$v},expression:"dimensionVisibleFilter"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VSelect,{attrs:{"items":_vm.selectOptions.blocked,"label":_vm.t('Filter Customer: Blocked'),"item-text":"title","item-value":"value","clearable":"","dense":"","attach":"","chips":"","multiple":"","hide-details":"","open-on-clear":"","small-chips":""},model:{value:(_vm.blockedFilter),callback:function ($$v) {_vm.blockedFilter=$$v},expression:"blockedFilter"}})],1)],1),_c(VDivider,{staticClass:"mt-4"}),_c('vuex-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.customers,"options":_vm.options,"search":_vm.search,"meta":_vm.meta,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [5, 10, 25, 50, 100]
      }},on:{"update:search":function($event){_vm.search=$event},"update:meta":function($event){_vm.meta=$event},"update:options":function (o) { return _vm.options = o; }},scopedSlots:_vm._u([{key:"item.displayName",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name: 'customer-view', params : { id : item.id } }}},[_vm._v(" "+_vm._s(item.displayName)+" ")]),_c('small',[_vm._v(_vm._s(item.email))])],1)])]}},{key:"item.blocked",fn:function(ref){
      var item = ref.item;
return [(item.blocked)?_c(VChip,{staticClass:"v-chip-light-bg font-weight-semibold text-uppercase me-1",class:_vm.resolveCustomerBlockedVariant(item.blocked),attrs:{"small":""}},[_vm._v(" "+_vm._s(item.blocked)+" ")]):_vm._e()]}},{key:"item.dimensionHidden",fn:function(ref){
      var item = ref.item;
return [(item.dimensionHidden)?_c(VChip,{staticClass:"v-chip-light-bg font-weight-semibold text-uppercase error--text",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.t('HIDE APP'))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","to":{ name: 'customer-view', params: { id: item.id } }}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Customer")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }